import React from 'react'
import Image from '../Components/assets/images/blogs-img.jpg'
import Image1 from '../Components/assets/images/blog-img1.png'
import Image2 from '../Components/assets/images/blog-img2.png'
import Image3 from '../Components/assets/images/blog-img3.png'
import Image4 from '../Components/assets/images/blog-img4.png'
import Image5 from '../Components/assets/images/blog-img5.png'


export default function Blog() {
    return (
        <>
            <div className='banner'>
                <div className='banner-img' style={{ backgroundImage: `url(${Image})` }}>
                    <div className='overlay'>
                        <div className="slider-container-inner">
                            <div className="slide-description">
                                <h2 className="slide-title">
                                    <a href="#enigge-meaning">Our Knowledge</a>
                                </h2>
                                <div className="section-subtitle">
                                    <p>As part of our job, we get to research and learn new things every day, so we want to share some of those works with our readers. Any critics or concerns are happily welcomed, so do write to us. Happy reading!</p>
                                </div>
                                <div className='btn-module'>
                                    <a className="contact-link" href="#enigge-meaning">Read Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row blog-content'>
                    <div className='blog-header blog-page'>
                        <h2>All Blogs</h2>
                    </div>
                    <div className='row'>
                            <div className='col-lg-4 col-md-6 '>
                                <div className='blog-card'>
                                    <div className='blog-card-img'>
                                        <img className='blog-img' src={Image1} />
                                    </div>
                                    <div className='blog-card-content'>
                                        <h2 className="entry-title">
                                            <a href="https://enigge.com/windows-11-a-much-needed-visual-overhaul/">Windows 11: A much needed Visual Overhaul</a>
                                        </h2>
                                        <p className="post-meta"><span className="published">Jul 1, 2021</span> | <a href="https://enigge.com/category/tech-feature/" rel="tag">Tech Feature</a> | 0 Comments</p>
                                        <div className="post-meta">
                                            <div className="post-meta-inner">
                                                <p>On June 24th Microsoft announced the latest version of Windows; Windows 11 and along with a bunch...</p>
                                            </div>
                                        </div>
                                        <a href="https://enigge.com/windows-11-a-much-needed-visual-overhaul/" className="more-link">read more</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 '>
                                <div className='blog-card'>
                                    <div className='blog-card-img'>
                                        <img className='blog-img' src={Image2} />
                                    </div>
                                    <div className='blog-card-content'>
                                        <h2 className="entry-title">
                                            <a href="https://enigge.com/how-to-install-macos-monterey-developers-beta-on-your-mac/">How To Install MacOS Monterey Developers Beta On Your Mac</a>
                                        </h2>
                                        <p className="post-meta"><span className="published">Jun 20, 2021</span> | <a href="https://enigge.com/category/tech-guides/" rel="tag">Tech Guides</a> | 0 Comments</p>
                                        <div className="post-meta">
                                            <div className="post-meta-inner">
                                                <p>MacOS Monterey is the latest iteration of Apple's OS for Mac Devices and it has got the major...</p>
                                            </div>
                                        </div>
                                        <a href="https://enigge.com/windows-11-a-much-needed-visual-overhaul/" className="more-link">read more</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 '>
                                <div className='blog-card'>
                                    <div className='blog-card-img'>
                                        <img className='blog-img' src={Image3} />
                                    </div>
                                    <div className='blog-card-content'>
                                        <h2 className="entry-title">
                                            <a href="https://enigge.com/3-things-you-can-incorporate-from-clubhouse-for-business-growth/">3 Things You Can Incorporate From Clubhouse For business growth?</a>
                                        </h2>
                                        <p class="post-meta"><span className="published">Jun 9, 2021</span> | <a href="https://enigge.com/category/tech-feature/" rel="tag">Tech Feature</a> | 0 Comments</p>
                                        <div className="post-meta">
                                            <div className="post-meta-inner">
                                                <p>The Clubhouse has taken the world by storm, and there are very distinct...</p>
                                            </div>
                                        </div>
                                        <a href="https://enigge.com/windows-11-a-much-needed-visual-overhaul/" className="more-link">read more</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 '>
                                <div className='blog-card'>
                                    <div className='blog-card-img'>
                                        <img className='blog-img' src={Image4} />
                                    </div>
                                    <div className='blog-card-content'>
                                        <h2 class="entry-title">
                                            <a href="https://enigge.com/windows-11-a-much-needed-visual-overhaul/">Clubhouse: A new kid on the block</a>
                                        </h2>
                                        <p className="post-meta">by <span className="author vcard"><a href="https://enigge.com/author/enigge/" title="Posts by enigge" rel="author">enigge</a></span> | <span className="published">Jun 7, 2021</span> | <a href="https://enigge.com/category/tech-feature/" rel="tag">Tech Feature</a> | 0 Comments</p>
                                        <div className="post-meta">
                                            <div className="post-meta-inner">
                                                <p>The Social Media space is a crowded one with tech giants like Facebook, Twitter, Instagram and Snapchat having a firm hold. While newcomers keep trying to lure you with all sorts of whacky and interesting ideas in the hope, you have gotten bored of the established...</p>
                                            </div>
                                        </div>
                                        <a href="https://enigge.com/windows-11-a-much-needed-visual-overhaul/" className="more-link">read more</a>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6 '>
                                <div className='blog-card'>
                                    <div className='blog-card-img'>
                                        <img className='blog-img' src={Image5} />
                                    </div>
                                    <div className='blog-card-content'>
                                        <h2 class="entry-title">
                                            <a href="https://enigge.com/windows-11-a-much-needed-visual-overhaul/">SEO For New Websites</a>
                                        </h2>
                                        <p className="post-meta">by <span className="author vcard"><a href="https://enigge.com/author/enigge/" title="Posts by enigge" rel="author">enigge</a></span> | <span className="published">Feb 1, 2021</span> | <a href="https://enigge.com/category/tech-feature/" rel="tag">Uncategorized</a> | 0 Comments</p>
                                        <div className="post-meta">
                                            <div className="post-meta-inner">
                                                <p>What is SEO? SEO stands for Search Engine Optimization. It means telling the search engines like Google what your site is all about. You need to specify it to Google so that when somebody searches for a relevant keyword, your website appears with a solution. Why do...</p>
                                            </div>
                                        </div>
                                        <a href="https://enigge.com/windows-11-a-much-needed-visual-overhaul/" className="more-link">read more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>


        </>
    )
}
