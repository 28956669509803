import React from 'react'

export default function Blogdetail() {
  return (
    <>
    
    
    
    </>
  )
}
