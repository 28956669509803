import React from 'react'
import Image1 from '../../assets/images/blog-img1.png';
import Image2 from '../../assets/images/blog-img2.png';
import Image3 from '../../assets/images/blog-img3.png';

export default function Homeblog() {
    return (
        <>
            <div className='my-container'>
                <div className='content-container'>
                    <div className='blog-content'>
                        <div className='blog-header'>
                            <h2>From Our Blogs</h2>
                        </div>
                        <div className='blog-description'>
                            <p>As part of our job, we get to research and learn new things every day, so we want to share some of those works with our readers. Any critics or concerns are happily welcomed, so do write to us. <br />Happy reading!</p>
                        </div>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6 '>
                                <div className='blog-card'>
                                    <div className='blog-card-img'>
                                        <img className='blog-img' src={Image1} />
                                        {/* <span className="inline-icon"><i className="fa-solid fa-bars"></i></span> */}
                                    </div>
                                    <div className='blog-card-content'>
                                        <h2 class="entry-title">
                                            <a href="https://enigge.com/windows-11-a-much-needed-visual-overhaul/">Windows 11: A much needed Visual Overhaul</a>
                                        </h2>
                                        <p class="post-meta"><span class="published">Jul 1, 2021</span> | <a href="https://enigge.com/category/tech-feature/" rel="tag">Tech Feature</a> | 0 Comments</p>
                                        <div class="post-meta">
                                            <div class="post-meta-inner">
                                                <p>On June 24th Microsoft announced the latest version of Windows; Windows 11 and along with a bunch...</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 '>
                                <div className='blog-card'>
                                    <div className='blog-card-img'>
                                        <img className='blog-img' src={Image2} />
                                    </div>
                                    <div className='blog-card-content'>
                                        <h2 class="entry-title">
                                            <a href="https://enigge.com/how-to-install-macos-monterey-developers-beta-on-your-mac/">How To Install MacOS Monterey Developers Beta On Your Mac</a>
                                        </h2>
                                        <p class="post-meta"><span class="published">Jun 20, 2021</span> | <a href="https://enigge.com/category/tech-guides/" rel="tag">Tech Guides</a> | 0 Comments</p>
                                        <div class="post-meta">
                                            <div class="post-meta-inner">
                                                <p>MacOS Monterey is the latest iteration of Apple's OS for Mac Devices and it has got the major...</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 '>
                                <div className='blog-card'>
                                    <div className='blog-card-img'>
                                        <img className='blog-img' src={Image3} />
                                    </div>
                                    <div className='blog-card-content'>
                                        <h2 class="entry-title">
                                            <a href="https://enigge.com/3-things-you-can-incorporate-from-clubhouse-for-business-growth/">3 Things You Can Incorporate From Clubhouse For business growth?</a>
                                        </h2>
                                        <p class="post-meta"><span class="published">Jun 9, 2021</span> | <a href="https://enigge.com/category/tech-feature/" rel="tag">Tech Feature</a> | 0 Comments</p>
                                        <div class="post-meta">
                                            <div class="post-meta-inner">
                                                <p>The Clubhouse has taken the world by storm, and there are very distinct...</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pagination clearfix">
                        <div className="alignleft"><a href="https://enigge.com/page/2/?et_blog">« Older Entries</a></div>
                        <div className="alignright"></div>
                    </div>
                </div>
            </div>
        </>
    )
}
