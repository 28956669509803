import React from "react";
import image from '../../assets/images/offer-img4.jpg'

const AboutUs = () => {
  return (
    <div className="container">
      <div className="about-main">
        <div className="row">
          <div className="col-md-6">
            <div className="about-content-home">
              <h6>|| ABOUT COMPANY</h6>
              <h1>Your Partner for Software Innovation</h1>
              <p>
                Enigge is the partner of choice for many of the world’s
                leading enterprises, SMEs and technology challengers. We help
                businesses elevate their value through custom software
                development, product design, QA and consultancy services.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="about-img">
                <img src={image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
