import React from 'react'
import Image1 from '../Components/assets/images/portfolio-img1.png';
import Image2 from '../Components/assets/images/portfolio-img2.png';
import Image3 from '../Components/assets/images/portfolio-img3.png';
import Image4 from '../Components/assets/images/portfolio-img4.png';

export default function Portfolio() {
    return (
        <>



            <div className="service_contents container">
                <div className="section-title">
                    <h2>Portfolio</h2>
                </div>

                <div className="service_card">
                    <div className="row">

                    <div className="col-lg-3 col-md-6">
                            <div className="home-service-card">
                                <div className="home-service-card-image">
                                    <img className="img-fluid" src={Image1} />
                                </div>
                                <h6>Web Development</h6>
                                <a href="#">Read More</a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="home-service-card">
                                <div className="home-service-card-image">
                                    <img className="img-fluid" src={Image2} />
                                </div>
                                <h6>App Development</h6>
                                <a href="#">Read More</a>
                            </div>
                        </div>
                        
                        <div className="col-lg-3 col-md-6">
                            <div className="home-service-card">
                                <div className="home-service-card-image">
                                    <img className="img-fluid" src={Image3} />
                                </div>
                                <h6>Graphics Design</h6>
                                <a href="#">Read More</a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="home-service-card">
                                <div className="home-service-card-image">
                                    <img className="img-fluid" src={Image4} />
                                </div>
                                <h6>Digital Marketing</h6>
                                <a href="#">Read More</a>
                            </div>
                        </div>

                       

                        

                    </div>
                </div>
            </div>



            {/* <div classname="container">
                <div className='section-title'>
                <h2>Our Services</h2>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div className="home-service-card">
                            <div className="home-service-card-image">
                                <img className="img-fluid" src="https://creatudevelopers.com/uploads/medium-1600005783professional-graphic-design.jpg" />
                            </div>
                            <h6>Graphics Design</h6>
                            <a href="https://creatudevelopers.com/service/graphics-design">Read More</a>
                        </div>

                    </div>
                    <div className="col-md-4">
                        <div className="home-service-card">
                            <div className="home-service-card-image">
                                <img className="img-fluid" src="https://creatudevelopers.com/uploads/medium-1617180457dm.jpg" />
                            </div>
                            <h6>Digital Marketing</h6>
                            <a href="https://creatudevelopers.com/service/digital-marketing">Read More</a>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="home-service-card">
                            <div className="home-service-card-image">
                                <img className="img-fluid" src="https://creatudevelopers.com/uploads/medium-1617180480website.jpg" />
                            </div>
                            <h6>Web Development</h6>
                            <a href="https://creatudevelopers.com/service/web-development">Read More</a>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="home-service-card">
                            <div className="home-service-card-image">
                                <img className="img-fluid" src="https://creatudevelopers.com/uploads/medium-1617180439background-02.jpg" />
                            </div>
                            <h6>App Development</h6>
                            <a href="https://creatudevelopers.com/service/app-development">Read More</a>
                        </div>
                    </div>

                </div>
            </div> */}

        </>
    )
}
