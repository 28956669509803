import React, { useState } from "react";
import Image1 from "../../assets/images/card-img1.png";
import Image2 from "../../assets/images/card-img2.png";
import Image3 from "../../assets/images/card-img3.png";
import Image4 from "../../assets/images/card-img4.png";
import backGround from "../../assets/images/offer-img1.jpg";
import backGround1 from "../../assets/images/offer-img2.jpg";
import backGround2 from "../../assets/images/offer-img3.jpg";
import backGround3 from "../../assets/images/offer-img4.jpg";

export default function Secondbanner() {
//   const [hover, setHover] = useState(false);

//   const handleHover = (e,id) => {
//     e.preventDefault();
//     setHover(true);
//   };

  const [cart, setCart] = useState([
    {
      id: "1",
      title: "Digital",
      title2: "Marketing",
      des: "Whether it is to get your first group of customers through the door or to push the scope of your business beyond your walls, we can help. From pay-per-click management and SEO services to social media campaign, and content marketing, we develop a highly effective marketing strategy guaranteed to boost your brand.",
      icon: Image1,
      back: backGround,
    },
    {
      id: "2",
      title: "Graphics",
      title2: "Designing",
      des: "Effective marketing strategy demands for researched, thoughtful and eye-catching materials. We offer all kinds of graphic design services including logos and branding, business cards, corporate brochure, letterhead, web graphics, etc. We intend our designs to draw out a single response from all our clients: “WOW!!”",
      icon: Image2,
      back: backGround1,
    },
    {
      id: "3",
      title: "Software",
      title2: "Development",
      des: "We create compelling custom software and applications that are reliable, scalable and meet every client’s unique software requirements. Our versatile team can build solutions for any platform to complement our client’s demands of outreaching the ideal market i.e. desktop software, mobile apps.",
      icon: Image3,
      back: backGround2,
    },
    {
      id: "4",
      title: "Web ",
      title2: "Development",
      des: "A personal blogging wordpress website, a website to showcase your impressive portfolio,&nbsp; a website to bring in new customers or maybe a full fledged web application whatever it is we help make the most the ever growing web infrastructures for the growth and prosperity for you and your business.",
      icon: Image4,
      back: backGround3,
    },
  ]);

  return (
    <>
      <div className="main-slider">
        <div className="container">
          <div className="cart-main">
            <div className="about-content-home">
              <h6>|| WHY CHOOSE US</h6>
              <h1>
                Design the Concept <br />
                of Your Business Idea Now
              </h1>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              {cart.map((get, keys) => {
                return (
                  <div className="col-md-3">
                   
                      <div
                        className="cart-content mb-3"
                        style={{ backgroundImage: `url(${get.back})` }}
                      >
                        <h4>
                          {" "}
                          {get.title} <br /> {get.title2}{" "}
                        </h4>
                        <p> {get.des.substring(0, 100)}. . . . . </p>
                        <div className="row">
                          <div className="col-md-6">
                            <button className="card-btn ">
                              <a
                                href="https://enigge.com/contact-us/"
                                className="card-a"
                              >
                                {" "}
                                <span>
                                  <i class="fa-solid fa-arrow-right-long"></i>
                                </span>{" "}
                                Learn more
                              </a>
                            </button>
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3">
                            <div className="card-icon">
                              <img src={get.icon} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* <div className='my-container'>
                <div className='row blurb-row'> 
                    <div className='col-lg-3 col-md-6 col-sm-6'>
                        <div className='blurb-content-card'>
                            <div className='blurb-content'> 
                                <div className='blurb-img'>
                                    <img src={Image1} className='blurb-main-img' alt='...' />
                                </div>
                                <div className='blurb-container'>
                                    <h4 className='module-header'>Digital Marketing</h4>
                                    <div className='blurb-description'>
                                        <div className='blurb-wrapper'>
                                            <p>Whether it is to get your first group of customers through the door or to push the scope of your business beyond your walls, we can help. From pay-per-click management and SEO services to social media campaign, and content marketing, we develop a highly effective marketing strategy guaranteed to boost your brand.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='btn-module'>
                                <a class="contact-link" href="https://enigge.com/contact-us/">Learn more</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-6'>
                        <div className='blurb-content-card'>
                            <div className='blurb-content'>
                                <div className='blurb-img'>
                                    <img src={Image2} className='blurb-main-img' alt='...' />
                                </div>
                                <div className='blurb-container'>
                                    <h4 className='module-header'>Graphics Designing</h4>
                                    <div className='blurb-description'>
                                        <div className='blurb-wrapper'>
                                            <p>Effective marketing strategy demands for researched, thoughtful and eye-catching materials. We offer all kinds of graphic design services including logos and branding, business cards, corporate brochure, letterhead, web graphics, etc. We intend our designs to draw out a single response from all our clients: “WOW!!”</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='btn-module'>
                                <a class="contact-link" href="https://enigge.com/contact-us/">Learn more</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-6'>
                        <div className='blurb-content-card'>
                            <div className='blurb-content'>
                                <div className='blurb-img'>
                                    <img src={Image3} className='blurb-main-img' alt='...' />
                                </div>
                                <div className='blurb-container'>
                                    <h4 className='module-header'>Software Development</h4>
                                    <div className='blurb-description'>
                                        <div className='blurb-wrapper'>
                                            <p>We create compelling custom software and applications that are reliable, scalable and meet every client’s unique software requirements. Our versatile team can build solutions for any platform to complement our client’s demands of outreaching the ideal market i.e. desktop software, mobile apps.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='btn-module'>
                                <a class="contact-link" href="https://enigge.com/contact-us/">Learn more</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-6'>
                        <div className='blurb-content-card'>
                            <div className='blurb-content'>
                                <div className='blurb-img'>
                                    <img src={Image4} className='blurb-main-img' alt='...' />
                                </div>
                                <div className='blurb-container'>
                                    <h4 className='module-header'>Web Development</h4>
                                    <div className='blurb-description'>
                                        <div className='blurb-wrapper'>
                                            <p>A personal blogging wordpress website, a website to showcase your impressive portfolio,&nbsp; a website to bring in new customers or maybe a full fledged web application whatever it is we help make the most the ever growing web infrastructures for the growth and prosperity for you and your business.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='btn-module'>
                                <a class="contact-link" href="https://enigge.com/contact-us/">Learn more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
}
