import './App.css';
import Footer from './Components/Footer/Footer';
import Navbar from './Components/Navbar/Navbar';
import Homerouting from './HomeRouting/Homerouting';

function App() {
  return (
    <>
    <Navbar />
    <Homerouting />
    <Footer />
      
    </>
  );
}

export default App;
