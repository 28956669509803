import React from 'react'
import AboutUs from '../Components/BodyComponents/AboutUs/AboutUs'
import BannerSlider from '../Components/BodyComponents/BannerSlider/BannerSlider'
import Form from '../Components/BodyComponents/form/form'
import Homeblog from '../Components/BodyComponents/homeblog/homeblog'
import Mainbanner from '../Components/BodyComponents/mainbanner/mainbanner'
import Offer from '../Components/BodyComponents/offer/offer'
import Secondbanner from '../Components/BodyComponents/secondbanner/secondbanner'

export default function Home() {
  return (
    <>
    <Mainbanner />
    <BannerSlider />
    <AboutUs />
    <Secondbanner />
    {/* <Offer /> */}
    <Homeblog />
    <Form />

    </>
  )
}
