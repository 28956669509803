import React from "react";
import Image1 from '../Components/assets/images/digital-img1.jpg';
import Image2 from "../Components/assets/images/digital-img2.jpg";
import Image3 from "../Components/assets/images/digital-img3.jpg";
import Image4 from '../Components/assets/images/digital-img4.jpg';
import Image5 from '../Components/assets/images/digital-img5.jpg';
import Image6 from '../Components/assets/images/digital-img6.jpg';
import Image7 from '../Components/assets/images/digital-img7.jpg';
import Image8 from '../Components/assets/images/digital-img8.jpg';
import Image9 from '../Components/assets/images/divider-img.svg';

export default function Digitalmarketing() {
    return (
        <>



            <div className='banner'>

                <div className='banner-img' style={{ backgroundImage: `url(${Image1})` }}>
                    <div className='overlay contact'>
                        <div className="slider-container-inner">
                            <div class="slide-description">
                                <h2 class="slide-title">
                                    <a href="#enigge-meaning">Digital Marketing</a>
                                </h2>
                                <div className="section-subtitle">
                                    <p>The way we interact with the web has changed a lot, it’s where people come across, connect and access day to day services. It has become more dynamic than ever. We strive to make web more functional, engaging, beautiful and intuitive.</p>
                                </div>
                                <div className='btn-module'>
                                    <a class="contact-link" href="#enigge-meaning">Contact Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="service_contents container">
                <div className="service_heading">
                    <h2>Digital Marketing</h2>
                </div>

                <div className="service_card">
                    <div className="row">
                        
                        <div className="col-lg-4 col-md-4">
                            <div className="card_contents">
                                <div className="card_icon_contents">
                                    <div className="card_icon">
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </div>
                                </div>
                                <div className="card_heading">
                                    <h4>Search Engine Optimization</h4>
                                </div>
                                <div className="card_para">
                                    <p>
                                        Search Engine Optimization or SEO is the dynamic procedure
                                        applied to place your website in the first pages of search
                                        engines like Google and Bing for your niche.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="card_contents">
                                <div className="card_icon_contents">
                                    <div className="card_icon">
                                        <i class="fa-regular fa-envelope"></i>
                                    </div>
                                </div>
                                <div className="card_heading">
                                    <h4>Email Marketing</h4>
                                </div>
                                <div className="card_para">
                                    <p>
                                        Our email marketing is a perfect blend of copywriting,
                                        content writing, designing, and market research to present
                                        engaging contents to entice new clients and turn past
                                        customers into a patron.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="card_contents">
                                <div className="card_icon_contents">
                                    <div className="card_icon">
                                        <i class="fa-brands fa-facebook-f"></i>
                                    </div>
                                </div>
                                <div className="card_heading">
                                    <h4>Social Media Management</h4>
                                </div>
                                <div className="card_para">
                                    <p>
                                        Are you tired of posting on Facebook only to get the
                                        engagement of a mere 50-100? We help you grow your audience,
                                        acquire new customers, build your brand awareness, build and
                                        maintain relationships. We deal with social media and
                                        marketing complexities to enable you to focus on running
                                        your business.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <div className="card_contents2">
                                <div className="card_icon_contents">
                                    <div className="card_icon">
                                        <i class="fa-regular fa-arrow-pointer"></i>
                                    </div>
                                </div>
                                <div className="card_heading">
                                    <h4>Pay-Per-Click (PPC) Marketing</h4>
                                </div>
                                <div className="card_para">
                                    <p>
                                        While we truly believe in our outstanding SEO team to
                                        produce results, SEO in nature takes time and is a long term
                                        growth. Meanwhile, PPC excels in providing a fast return on
                                        investment (ROI). Enigge is known for thinking out of the
                                        box and standing out; you can be assured that the investment
                                        you make is put into work for your business. Enigge’s way of
                                        PPC: Comprehensive keyword research Unique and engaging ads
                                        Top-notch design Finest Copywriting
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="card_contents2">
                                <div className="card_icon_contents">
                                    <div className="card_icon">
                                        <i class="fa-regular fa-comments"></i>
                                    </div>
                                </div>
                                <div className="card_heading">
                                    <h4>Content Creation and Marketing</h4>
                                </div>
                                <div className="card_para">
                                    <p>
                                        Content Creation not just a luxury but a necessity in 2020.
                                        Content is the essence of every digital experience – whether
                                        that’s a website, a display ad, a social media post or an
                                        email marketing campaign. It is how you and your brand
                                        communicates with your clients. Businesses with blogs get
                                        126 percent more lead growth than those who don’t.
                                        Similarly, eighty-two percent of consumers develop a
                                        positive association with a company after reading custom
                                        content.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="card_contents2">
                                <div className="card_icon_contents">
                                    <div className="card_icon">
                                        <i class="fa-regular fa-link"></i>
                                    </div>
                                </div>
                                <div className="card_heading">
                                    <h4>Link Building</h4>
                                </div>
                                <div className="card_para">
                                    <p>
                                        Link Building is and has been one of the most important
                                        Google Ranking Factors. It is not necessarily as important
                                        as it used to be, but when just a search results position
                                        has stakes on thousands and millions of traffic, every
                                        little detail matters. With our experience with a plethora
                                        of clients, we can help you collaborate with our clients in
                                        your niche. Thus helping you build relevant and high-quality
                                        backlinks.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <div className="card_contents3">
                                <div className="card_icon_contents">
                                    <div className="card_icon">
                                        <i class="fa-regular fa-lightbulb"></i>
                                    </div>
                                </div>
                                <div className="card_heading">
                                    <h4>Keyword Strategy</h4>
                                </div>
                                <div className="card_para">
                                    <p>
                                        “Keyword” is the key word in Digital Marketing. A keyword is
                                        a set of words that are generally searched for by customers.
                                        Everyone wants to be rank first, that’s why everyone can’t
                                        rank #1. We, with our in-depth studies and analysis, figure
                                        out the best keywords for your business website as well as
                                        your competitors’. As such that you can establish your brand
                                        as well as capitalize on your competitors.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service_descriptions">
                    <div className="service_heading">
                        <h3>Make Google Your Best Ally</h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 col-md-5">
                            <div className="service_image">
                                <img src={Image2} alt="service image" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7">
                            <div className="desc">
                                <p>
                                    These days people seldom type in the url of the site they want
                                    to visit, they Google it. This brings forth few challenges but
                                    several opportunities as well. Challenge is that you need to
                                    compete against millions of other and you might get lost in
                                    the crowd. That’s where we help you, we make you stand out
                                    from the crowd while still being functional and helpful to
                                    your end consumers.
                                </p>
                                <p>
                                    Similarly, the other benefit is you can get millions of new
                                    customers through Google alone. With our SEO team, you can be
                                    assured of best in class SEO and high quality traffics through
                                    search engines like Google alone. Not only that our SEO
                                    guarantees you of:
                                </p>
                                <ul>
                                    <li>Enhanced Brand Value</li>
                                    <li>Competitive Edge</li>
                                    <li>Qualified Search Traffic</li>
                                    <li>Stand Out From The Competition</li>
                                    <li>Increased Traffic</li>
                                    <li>Boosted Sales</li>
                                    <li>Escalated Earnings</li>
                                    <li>Business Prosperity</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service_descriptions">
                    <div className="service_heading">
                        <h3>Send Personalized Message to the Customers</h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 col-md-7">
                            <div className="desc">
                                <p>
                                    Imagine being able to send a personalized to each and every customers about their interest. Wouldn’t it be great? Fortunately, you can already do it. It’s called Email Marketing. I know what you’re saying, good ol’ Email is dead and buried, nobody uses Email these days in the day and age iMessage, WhatsApp, Messengers, Slack and what not. But you couldn’t be further from the truth.
                                </p>
                                <p>
                                    The best time for Email Marketing is NOW, here’s why?
                                </p>
                                <ul>
                                    <li>94% of people check email EVERY DAY!
                                        ROI of Email Marketing is 4400%, i.e., if you invest in EM for every dollar, you earn 44 dollars.
                                    </li>
                                    <li>You are in charge; tomorrow, Facebook might decide that you violated their ever-growing, complicated and vague Terms and Conditions; all your followers are gone. Trust me I can say that from my experience. But with Email Marketing, none can take it away from you.

                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5">
                            <div className="service_image">
                                <img src={Image3} alt="service image" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service_descriptions">
                    <div className="service_heading">
                        <h3>Engaging Content on Social Medias</h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 col-md-5">
                            <div className="service_image">
                                <img src={Image4} alt="service image" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7">
                            <div className="desc">
                                <p>
                                    Social Medias has taken the world by storm. These days anyone with internet access have social media account of some sort and if your business is not on social media, you’re missing out. But, being there is not enough, you need to have engaging content that keeps your clients coming back. That why our astute team of Social Media Specialists will help not only get more likes and followers but also the thing that matters you the most, be it more sales, revenue or relationships.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service_descriptions">
                    <div className="service_heading">
                        <h3>Only Pay for Ads that CONVERT</h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 col-md-7">
                            <div className="desc">
                                <p>
                                    PPC is a form of marketing where you bid for certain keyword in Search Engines like Google and pay only for those ads that were clicked by the customers. It is great short term tactics for gaining sales and traffic but that doesn’t it has to be done right. Everyone’s competing mostly on the same keyword and you need to stand out.
                                </p>
                                <p>
                                    Enigge way of PPC to provide you the best result possible:
                                </p>
                                <ul>
                                    <li>Comprehensive Keyword Research</li>
                                    <li>Unique and Engaging Ads</li>
                                    <li>Top-notch Design</li>
                                    <li>Finest Copywriting</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5">
                            <div className="service_image">
                                <img src={Image5} alt="service image" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service_descriptions">
                    <div className="service_heading">
                        <h3>Help Out Customers</h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 col-md-5">
                            <div className="service_image">
                                <img src={Image6} alt="service image" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7">
                            <div className="desc">
                                <p> Why you need Content Creation and Marketing:</p>
                                <ul>
                                    <li>Businesses with blogs get 126 percent more lead growth than those who don’t.</li>
                                    <li>Eighty-two percent of consumers develop a positive association with a company after reading custom content.</li>
                                    <li>Content marketing generates over three times as many leads as outbound marketing and costs 62 percent less.</li>

                                </ul>
                                <p>  Why You Would Want Enigge to do Content Writing and Marketing? </p>
                                <ul>
                                    <li>Content that adds value; we focus on creating content that provides value to the audience.</li>
                                    <li>Your Stories matter; your stories deserve to be heard and admired.</li>
                                    <li>Audience Analysis; our content fills the void, focus on the unaddressed needs of the audience.</li>
                                    <li>SEO Optimized; Content is entirely SEO optimized and helps to enhance the Authority of the business.</li>
                                    <li>Revenue Generating; With all the above combined, you are bound to see tremendous growth in revenue.</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service_descriptions">
                    <div className="service_heading">
                        <h3>Collaborate to Success</h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 col-md-7">
                            <div className="desc">
                                <p>
                                    As mentioned above, link building is not as big of a thing as it was couple of years ago. But, that doesn’t mean it worthless. It still has its place, there are various ways of link building but we, here at Enigge, believe in link building for real life – collaboration. With our prosperous and sound relationship with thousands of client help you to get together with similar brands in your niche. That way you can collaborate for mutual benefits. WIN-WIN for all parties involved.
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5">
                            <div className="service_image">
                                <img src={Image7} alt="service image" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service_descriptions">
                    <div className="service_heading">
                        <h3>Get Discovered Quicker</h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 col-md-5">
                            <div className="service_image">
                                <img src={Image8} alt="service image" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7">
                            <div className="desc">
                                <p>It can be really tough for new website to gain traffic right away, we can tell you about that with our first hand experience. However, working on your strengths and good research can take you a ling way. With our adept team, we first help you rank on the easier and less competitive keyword to get the snowball rolling and when the ball has gained enough momentum, we make sure that you are top ranked in most keywords in your niche.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="accordion-div">
                <div className="accordion-header">
                    <h2>Our Unique Procedure to Digital Marketing</h2>
                </div>
                <div className="accordion-module">
                    <div className="accordion-item">
                        <h5 className="toggle-title">Research</h5>
                        <div className="toggle-content clearfix" style={{ display: 'block' }}>
                            <p>We understand that every industry and every company have its fair share of complications and challenges. That’s why we study and review from different point of view – customers, you as a company, the industry in general, technology in your field and many more.</p>
                        </div>
                    </div>
                    <div className="accordion-item close">
                        <h5 className="toggle-title" >Prototyping <i className="fa-solid fa-circle-plus"></i></h5>
                        <div className="toggle-content clearfix" style={{ display: 'none' }}>
                            <p>We map out our ideas and findings from researches and vigorous studies into a prototype as vividly as possible. This help in ironing out few rough edges.</p>
                        </div>
                    </div>
                    <div className="accordion-item close">
                        <h5 className="toggle-title">Experimentation<i className="fa-solid fa-circle-plus"></i></h5>
                        <div className="toggle-content clearfix" style={{ display: 'none' }}>
                            <p>Not all ideas are as great as they seem to be in our head. It must be verified by the real world or the end customers. So, we take surveys and study the customers to find out their pain point, the unaddressed needs.</p>
                        </div>
                    </div>
                    <div className="accordion-item close">
                        <h5 className="toggle-title">Implementation<i className="fa-solid fa-circle-plus"></i></h5>
                        <div className="toggle-content clearfix" style={{ display: 'none' }}>
                            <p>Finally, after numerous amendments, testing and studies we step into the market to make our presence felt – YOUR presence felt.</p>
                        </div>
                    </div>
                    <div className="accordion-item close">
                        <h5 className="toggle-title">Review<i className="fa-solid fa-circle-plus"></i></h5>
                        <div className="toggle-content clearfix" style={{ display: 'none' }}>
                            <p>When everything is said and done, we give a detailed report and review of how things went and how can we improve the next time around.</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            


            <div className="top-divider">
                <div className="top-inside-divider" style={{ backgroundImage: `url(${Image9})` }} />
                <div className="divider">
                    <div className="divider-content">
                        <div className="divider-header">
                            <h3>We’ve Got You Covered</h3>
                        </div>
                        <div className='btn-module'>
                            <a class="contact-link" href="https://enigge.com/contact-us/">Let's Talk<i className="fa-solid fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

