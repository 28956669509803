import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../Pages/Home'
import About from '../Pages/About'
import Contact from '../Pages/Contact'
import DigitalMarketing from '../Pages/Digitalmarketing'
import Portfolio from '../Pages/Portfolio'
import Blog from '../Pages/Blog'
import Blogdetail from '../Pages/Blogdetail'


export default function Homerouting() {
  return (
    <>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/digitalmarketing' element={<DigitalMarketing />} />
        <Route exact path='/portfolio' element={<Portfolio />} />
        <Route exact path='/blog' element={<Blog />} />
        <Route exact path='/blogdetail' element={<Blogdetail />} />
      </Routes>
    </>
  )
}


