import React from 'react'
import Image from '../Components/assets/images/about-img.jpg';
import Image2 from '../Components/assets/images/divider-img.svg';

export default function About() {
    return (
        <>
            <div className='banner'>
                <div className='banner-img' style={{ backgroundImage: `url(${Image})` }}>
                    <div className='overlay'>
                        <div className="slider-container-inner">
                            <div class="slide-description">
                                <h2 class="slide-title">
                                    <a href="#enigge-meaning">About Us</a>
                                </h2>
                                <div className="section-subtitle">
                                    <p>Who Are We And Why You Would Want To Join Hands With Us!</p>
                                </div>
                                <div className='btn-module'>
                                    <a class="contact-link" href="#enigge-meaning">What Does Enigge Mean?</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='about-content'>
                        <div className='about-heading'>
                            <h2>Who Are We?</h2>
                        </div>
                        <div class="about-description">
                            <p>Enigge was established with a vision to be a part of the ever-changing IT industry and provide the best services thriving in it. We are a team of highly motivated like-minded geeks with a single goal in mind, use the knowledge and skills we share to develop the best product and provide the best services to our clients.</p>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>


                <div className="blockquote-wrap">
                    <blockquote>
                        <h4 className="blockquote-text">
                            <span>Enigge (in Dutch “Enige”) meaning “Only One”, which we strive to be.</span>
                        </h4>
                    </blockquote>
                </div>

                <div className="accordion-header">
                    <h2>Our Values</h2>
                </div>

                <div className="accordion-module">
                    <div className="accordion-item">
                        <h5 className="toggle-title">Craft With Excellence</h5>
                        <div className="toggle-content clearfix" style={{ display: 'block' }}>
                            <p>“Excellence is in the details. Give attention to the details and excellence will come.”</p>
                            <p>We incorporate this philosophy in our daily task and are meticulous about everything we do. Our golden rule is to include only what is important, making sure those necessary elements are beautiful.</p>
                        </div>
                    </div>
                    <div className="accordion-item close">
                        <h5 className="toggle-title" >Customer Satisfaction <i className="fa-solid fa-circle-plus"></i></h5>
                        <div className="toggle-content clearfix" style={{ display: 'none' }}>
                            <p>Customer satisfaction is important to us, but customer loyalty is what we aim for every day. We take it very seriously and go into much depth to make that happen.</p>
                        </div>
                    </div>
                    <div className="accordion-item close">
                        <h5 className="toggle-title">Accountability &amp; Trust <i className="fa-solid fa-circle-plus"></i></h5>
                        <div className="toggle-content clearfix" style={{ display: 'none' }}>
                            <p>We operate with the utmost transparency, which we believe is a foundation to building a strong foundation of trust and accountability within both our team and with our clients.</p>
                        </div>
                    </div>
                </div>




                {/* <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            Craft With Excellence
                            </button>
                        </h2>
                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body"><p>“Excellence is in the details. Give attention to the details and excellence will come.”</p>
                            <p>We incorporate this philosophy in our daily task and are meticulous about everything we do. Our golden rule is to include only what is important, making sure those necessary elements are beautiful.</p></div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            Customer Satisfaction
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body"> <p>Customer satisfaction is important to us, but customer loyalty is what we aim for every day. We take it very seriously and go into much depth to make that happen.</p></div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            Accountability &amp; Trust 
                            </button>
                        </h2>
                        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body"><p>We operate with the utmost transparency, which we believe is a foundation to building a strong foundation of trust and accountability within both our team and with our clients.</p>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>


            <div className="top-divider">
                <div className="top-inside-divider" style={{ backgroundImage: `url(${Image2})` }} />
                <div className="divider">
                    <div className="divider-content">
                        <div className="divider-header">
                            <h3>Grow With Enigge</h3>
                        </div>
                        <div className='btn-module'>
                            <a class="contact-link" href="https://enigge.com/contact-us/">Let's Talk<i className="fa-solid fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}
