import React from "react";
import Logo from "../assets/images/header-logo.png";
import { Link, NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className="first-footer">
        <div className="my-container">
          <div className="content-container">
            <div className="footer-main">
              <div className="logo d-flex justify-content-center">
                <Link to="/">
                  <img className="logo-img" src={Logo} alt="logo" />
                </Link>
              </div>
              <div className="row">
                <div className="col-md-3"></div>
                {/* <div className="col-md-6">
                  <div className="footer-content">
                    <div className="footer-form ">
                      <div className="subscribe-form">
                        <div className="d-flex justify-content-center">
                        <h2>subscribe so you never miss out the moment</h2>
                        </div>
                        <input
                          type="email"
                          className="subscribe-form"
                          id="exampleFormControlInput1"
                          placeholder="Email"
                        />
                        <a href="#">
                          <p className="subscribe-btn">
                            {" "}
                            Subscribe
                            <i className="fa-solid fa-arrow-right"></i>
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-3"></div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="footer-inner">
                    <h3>
                      <i class="fa-solid fa-question"></i>
                    </h3>
                    <h4>Sales and Information</h4>
                    <p>
                      <a href="mailto:hello@enigge.com">hello@enigge.com</a>
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="footer-inner">
                    <h3>
                    <i class="fa-solid fa-phone"></i>
                    </h3>
                    <h4>Phone</h4>
                    <p>
                      <a href="tel:240-810-3666">240-810-3666</a>
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="footer-inner">
                    <h3>
                    <i class="fa-solid fa-circle-info"></i>
                    </h3>
                    <h4>Support</h4>
                    <p>
                      <a href="mailto:support@enigge.com">support@enigge.com</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="footer-nav-main">
                <ul>
                  {/* <li>
                    <a
                      className="nav-item  dropdown-toggle"
                      href="#"
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="nav-link">services</span>
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li>
                        <NavLink
                          to="/digitalmarketing"
                          className="dropdown-item"
                        >
                          <span className="nav-link">digital marketing</span>
                        </NavLink>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          graphics designing
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          software development
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          web development
                        </a>
                      </li>
                    </ul>
                  </li> */}
                  <li>
                    {" "}
                    <NavLink to="/portfolio" className="nav-item">
                      <span className="nav-link">Service</span>
                    </NavLink>
                  </li>
                  <li>
                    {" "}
                    <NavLink to="/portfolio" className="nav-item">
                      <span className="nav-link">Portfolio</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/blog" className="nav-item">
                      <span className="nav-link">Blog</span>
                    </NavLink>
                  </li>
                  <li>
                    {" "}
                    <NavLink to="/about" className="nav-item">
                      <span className="nav-link">About Us</span>
                    </NavLink>
                  </li>
                </ul>
                <div className="end-footer">
                  <div className="end-footer-content">
                    <div className="end-footer-module">
                      <div className="end-footer-text">
                        Copyright &copy; {new Date().getFullYear()} || Enigge
                        InfoTech LLC
                      </div>
                    </div>
                  </div>
                </div>

                <div className="social-media">
                  <ul className="social-media-follow">
                    <li className="social-media-follow-network">
                      <a
                        href="https://www.facebook.com/enigge.infotech/"
                        className="social-icon"
                        title="Follow on Facebook"
                      >
                        <div className="social-media-icon">
                          <i className="fa-brands fa-facebook-f facebook"></i>
                        </div>
                      </a>
                    </li>

                    <li className="social-media-follow-network">
                      <a
                        href="https://www.instagram.com/enigge_infotech/"
                        className="social-icon"
                        title="Follow on Instagram"
                      >
                        <div className="social-media-icon">
                          <i className="fa-brands fa-instagram instagram"></i>
                        </div>
                      </a>
                    </li>

                    <li className="social-media-follow-network">
                      <a
                        href="https://twitter.com/enigge"
                        className="social-icon"
                        title="Follow on Twitter"
                      >
                        <div className="social-media-icon">
                          <i className="fa-brands fa-twitter twitter"></i>
                        </div>
                      </a>
                    </li>

                    <li className="social-media-follow-network">
                      <a
                        href="https://www.linkedin.com/company/enigge/"
                        className="social-icon"
                        title="Follow on LinkedIn"
                      >
                        <div className="social-media-icon">
                          <i className="fa-brands fa-linkedin-in linked-in"></i>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* <div className="footer-container">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <div className="input-group input-form mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type Here"
                    />
                    <span className="input-group-text" id="basic-addon2">
                      Search
                    </span>
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>
            </div> */}
            {/* <div className="footer-container">
              <div className="row">
                <div className="col-lg-3">
                  <div className="footer-content">
                    <div className="footer-form ">
                      <div className="subscribe-form">
                        <h2>subscribe so you never miss out the moment</h2>
                        <input
                          type="email"
                          className="subscribe-form"
                          id="exampleFormControlInput1"
                          placeholder="Email"
                        />
                        <a href="#">
                          <p className="subscribe-btn">
                            {" "}
                            Subscribe
                            <i className="fa-solid fa-arrow-right"></i>
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="social-media">
                    <ul className="social-media-follow">
                      <li className="social-media-follow-network">
                        <a
                          href="https://www.facebook.com/enigge.infotech/"
                          className="social-icon"
                          title="Follow on Facebook"
                        >
                          <div className="social-media-icon">
                            <i className="fa-brands fa-facebook-f facebook"></i>
                          </div>
                        </a>
                      </li>

                      <li className="social-media-follow-network">
                        <a
                          href="https://www.instagram.com/enigge_infotech/"
                          className="social-icon"
                          title="Follow on Instagram"
                        >
                          <div className="social-media-icon">
                            <i className="fa-brands fa-instagram instagram"></i>
                          </div>
                        </a>
                      </li>

                      <li className="social-media-follow-network">
                        <a
                          href="https://twitter.com/enigge"
                          className="social-icon"
                          title="Follow on Twitter"
                        >
                          <div className="social-media-icon">
                            <i className="fa-brands fa-twitter twitter"></i>
                          </div>
                        </a>
                      </li>

                      <li className="social-media-follow-network">
                        <a
                          href="https://www.linkedin.com/company/enigge/"
                          className="social-icon"
                          title="Follow on LinkedIn"
                        >
                          <div className="social-media-icon">
                            <i className="fa-brands fa-linkedin-in linked-in"></i>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="footer-content">
                    <div class="footer-inner">
                      <h4>Sales and Information</h4>
                      <p>
                        <a href="mailto:hello@enigge.com">hello@enigge.com</a>
                      </p>
                      <h4>Support</h4>
                      <p>
                        <a href="mailto:support@enigge.com">
                          support@enigge.com
                        </a>
                      </p>
                      <h4>Phone</h4>
                      <p>
                        <a href="tel:240-810-3666">240-810-3666</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="end-footer">
                <div className="end-footer-content">
                  <div className="end-footer-module">
                    <div className="end-footer-text">
                      Copyright &copy; {new Date().getFullYear()} || Enigge
                      InfoTech LLC
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
