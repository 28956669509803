import React from "react";
import Image1 from "../../assets/images/divider-img2.svg";

export default function Form() {
  return (
    <>
      <div className="home-page-form">
        <div className="container">
          <div className="enquiry">
            <div className="enquiry-heading form-enquiry-heading">
              <h2>Tell Us About Your Dream</h2>
            </div>
            <div className="form-enquiry-text">
              <p>
                Whether you have questions about our services, have a comment or
                if it’s Star Wars that you want to talk about, we are all ready
                to connect. Don’t be a stranger, say Hello!
              </p>
            </div>

            <div className="enquiry-inputs form-enquiry">
              <div className="row">
                <div className="col-lg-4 col-md-4 contact-field">
                  <input
                    type="text"
                    className="form-control enquiry-form"
                    placeholder="First name"
                  />
                </div>
                <div className="col-lg-4 col-md-4 contact-field">
                  <input
                    type="text"
                    className="form-control enquiry-form "
                    placeholder="Phone"
                  />
                </div>
                <div className="col-lg-4 col-md-4 contact-field">
                  <input
                    type="text"
                    className="form-control enquiry-form"
                    placeholder="Email Address"
                  />
                </div>

                <div className="col-lg-4 col-md-4 contact-field">
                  <input
                    type="text"
                    className="form-control enquiry-form"
                    placeholder="City"
                  />
                </div>
                <div className="col-lg-4 col-md-4 contact-field">
                  <input
                    type="text"
                    className="form-control enquiry-form"
                    placeholder="State/Province"
                  />
                </div>
                <div className=" col-lg-4 col-md-4 contact-field">
                  <input
                    type="texr"
                    className="form-control enquiry-form"
                    placeholder="Subject"
                  />
                </div>
                <div className="contact-field">
                  <textarea
                    className="form-control enquiry-form"
                    id="exampleFormControlTextarea1"
                    placeholder="Your Message"
                    rows={6}
                  />
                </div>
                <div className="bottom-container">
                  {/* <div className="contact-right">
                                        <p className="clearfix">
                                            <span className="contact-captcha-question">5 + 4</span> = <input type="text" size={2} className="contact-captcha" data-first_digit={5} data-second_digit={4} defaultValue name="et_pb_contact_captcha_0" data-required_mark="required" autoComplete="off" />
                                        </p>
                                    </div> */}
                  <div className="btn-module">
                    <a
                      class="contact-link"
                      href="https://enigge.com/contact-us/"
                    >
                      Submit<i className="fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-inside-divider">
          <img className="divider-img" src={Image1} alt="" />
        </div>
      </div>
    </>
  );
}
