import React from 'react'
import Image1 from '../../assets/images/carousel-img1.jpg';
import Image2 from '../../assets/images/carousel-img2.jpg';
import Image3 from '../../assets/images/carousel-img3.jpg';
import Image4 from '../../assets/images/carousel-img4.jpg';
import Image5 from '../../assets/images/carousel-img5.jpg';


export default function Mainbanner() {
    return (
        <>
            <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={Image1} className="d-block w-100 carousel-img" alt="..." />
                        <div class="carousel-caption d-none d-md-block">
                            <h2>Software Development</h2>
                            <p>Expect nothing but competitively priced custom software solutions; mobile apps, web applications, business software solutions, maintenance, and support, you can let us drive the wheel.</p>
                            <div className='btn-module'>
                        <a class="contact-link" href="https://enigge.com/software-development/">Learn more</a>
                        </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={Image2} className="d-block w-100 carousel-img" alt="..." />
                        <div class="carousel-caption d-none d-md-block">
                            <h2>Website Designing</h2>
                            <p>Expect nothing but competitively priced custom software solutions; mobile apps, web applications, business software solutions, maintenance, and support, you can let us drive the wheel.</p>
                            <div className='btn-module'>
                        <a class="contact-link" href="https://enigge.com/software-development/">Learn more</a>
                        </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={Image3} className="d-block w-100 carousel-img" alt="..." />
                        <div class="carousel-caption d-none d-md-block">
                            <h2>SEO</h2>
                            <p>Build a solid visibility of your business online and increase the value of your web content</p>
                            <div className='btn-module'>
                        <a class="contact-link" href="https://enigge.com/software-development/">Learn more</a>
                        </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={Image4} className="d-block w-100 carousel-img" alt="..." />
                        <div class="carousel-caption d-none d-md-block">
                            <h2>Graphics Designing</h2>
                            <p>Create striking designs for your brand to reflect the true identity and personality of your entire operation</p>
                            <div className='btn-module'>
                        <a class="contact-link" href="https://enigge.com/software-development/">Learn more</a>
                        </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={Image5} className="d-block w-100 carousel-img" alt="..." />
                        <div class="carousel-caption d-none d-md-block">
                            <h2>Social Media Marketing</h2>
                            <p>Create brand awareness; build relationship with you audience though active online engagement and targeted content sharing; turn brand awareness into brand loyalty</p>
                            <div className='btn-module'>
                        <a class="contact-link" href="https://enigge.com/software-development/">Learn more</a>
                        </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true" />
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true" />
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

        </>
    )
}
