import React from 'react'
import Image from '../Components/assets/images/contact-img.jpg';

export default function Contact() {
    return (
        <>
            <div className='banner'>
                <div className='banner-img' style={{ backgroundImage: `url(${Image})` }}>
                    <div className='overlay contact'>
                        <div className="slider-container-inner">
                            <div class="slide-description">
                                <h2 class="slide-title">
                                    <a href="#enigge-meaning">Get in Touch!</a>
                                </h2>
                                <div className="section-subtitle">
                                    <p>Your dream, your idea, our hands. Let’s create something great together.</p>
                                </div>
                                <div className='btn-module'>
                                    <a class="contact-link" href="#enigge-meaning">Contact Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className="contact">
                    <div className='row'>
                        <div className='col-lg-5 col-md-5'>
                            <div className='contact-container'>
                                <div className="contact-column">
                                    <div className="phone">
                                        <h4>Phone:</h4>
                                        <h4><a href="tel:+1 240.810.3666">+1 2408 103 666&nbsp;&nbsp;&nbsp;&nbsp;</a> <br /> (USA)</h4>
                                        <h4><a href="tel:+610449794691">+61 0449 794 691</a> (Australia)</h4>
                                    </div>
                                </div>
                                <div className="contact-column">
                                    <div className="sales">
                                        <h5>Sales and Information:</h5>
                                        <h6><a href="mailto:sales@enigge.com">sales@enigge.com</a></h6>
                                        <h5>Support:</h5>
                                        <h6><a href="mailto:support@enigge.com">support@enigge.com</a></h6>
                                    </div>
                                </div>
                            </div>
                            <div className="wpb-outline">
                                <div className="wpb-element ">
                                    <div className="wpb-wrapper">
                                        <p>Tag along to discover us on our social media sites.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='contact-media'>
                                <ul className="social-media-follow">
                                    <li className="social-media-follow-network">
                                        <a href="https://www.facebook.com/enigge.infotech/" className="icon-div" title="Follow on Facebook">
                                            <div className='media-icon'>
                                                <i className="fa-brands fa-facebook-f"></i>
                                            </div>
                                        </a>
                                    </li>

                                    <li className="social-media-follow-network">
                                        <a href="https://www.instagram.com/enigge_infotech/" className="social-icon contact-page-icon" title="Follow on Instagram">
                                            <div className='media-icon'>
                                                <i className="fa-brands fa-instagram"></i>
                                            </div>
                                        </a>
                                    </li>

                                    <li className="social-media-follow-network">
                                        <a href="https://twitter.com/enigge" className="social-icon" title="Follow on Twitter">
                                            <div className='media-icon'>
                                                <i className="fa-brands fa-twitter"></i>
                                            </div>
                                        </a>
                                    </li>

                                    <li className="social-media-follow-network">
                                        <a href="https://www.linkedin.com/company/enigge/" className="social-icon" title="Follow on LinkedIn">
                                            <div className='media-icon'>
                                                <i className="fa-brands fa-linkedin-in"></i>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>



                        <div className='col-lg-7 col-md-7'>
                            <div className='enquiry'>
                                <div className='enquiry-heading'>
                                    <h2>We can help, just give us a shout.</h2>
                                </div>

                                <div className='enquiry-inputs '>
                                    <div className="row">
                                        <div className="col-lg-6 mb-4">
                                            <input type="text" className="form-control enquiry-form" placeholder="First name" />
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <input type="text" className="form-control enquiry-form" placeholder="Last name" />
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <input type="text" className="form-control enquiry-form" placeholder="Email Address" />
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <input type="text" className="form-control enquiry-form " placeholder="Phone" />
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <input type="text" className="form-control enquiry-form" placeholder="City" />
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <input type="text" className="form-control enquiry-form" placeholder="State/Province" />
                                        </div>
                                        <div className="mb-3">
                                            <input type="texr" className="form-control enquiry-form" placeholder="Subject" />
                                        </div>
                                        <div className="mb-3">
                                            <textarea className="form-control enquiry-form" id="exampleFormControlTextarea1" placeholder="Your Message" rows={6} />
                                        </div>
                                        <div className="bottom-container">
                                            <div className="contact-right">
                                                <p className="clearfix">
                                                    <span className="contact-captcha-question">5 + 4</span> = <input type="text" size={2} className="contact-captcha" data-first_digit={5} data-second_digit={4} defaultValue name="et_pb_contact_captcha_0" data-required_mark="required" autoComplete="off" />
                                                </p>
                                            </div>
                                            <div className='btn-module'>
                                                <a class="contact-link" href="https://enigge.com/contact-us/">Submit<i className="fa-solid fa-arrow-right"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </>
    )
}
