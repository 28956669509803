import React from 'react'
import Logo from '../assets/images/header-logo.png';
import { Link, NavLink } from 'react-router-dom';

export default function Navbar() {
    return ( 
        <>

            <nav className="navbar navbar-expand-lg navbar-light sticky">
                <div className="container">
                    <div className='logo'>
                        <Link to="/">
                            <img className='logo-img' src={Logo} alt="logo" />
                        </Link>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav m-auto">
                            <li className="nav-item dropdown">
                                <a className="nav-link2 dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    services 
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    <li><NavLink to="/digitalmarketing" className="dropdown-item">
                                        <span className="nav-link">digital marketing</span>
                                    </NavLink></li>
                                    <li><a className="dropdown-item" href="#">graphics designing</a></li>
                                    <li><a className="dropdown-item" href="#">software development</a></li>
                                    <li><a className="dropdown-item" href="#">web development</a></li>
                                </ul>
                            </li>
                            <NavLink to="/portfolio" className="nav-item"><span className="nav-link2">portfolio</span></NavLink>
                            <NavLink to="/blog" className="nav-item"><span className="nav-link2">blog</span></NavLink>
                            <NavLink to="/about" className="nav-item"><span className="nav-link2">about us</span></NavLink>
                        </ul>

                    </div>
                    <div className='btn-module navbar-btn'>
                        <a class="contact-link" href="https://enigge.com/contact-us/">Contact Us<i className="fa-solid fa-arrow-right"></i></a>

                    </div>
                </div>
            </nav>

        </>
    )
}
