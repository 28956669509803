import React, { useState } from "react";
import Slider from "react-slick";
import image from "../../assets/images/icon.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BannerSlider = () => {
  const [slide, setSlide] = useState([
    {
      image: image,
    },
    {
      image: image,
    },
    {
      image: image,
    },
    {
      image: image,
    },
    {
      image: image,
    },
    {
      image: image,
    },
    {
      image: image,
    },
  ]);

  var settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 5,
    slidesToScroll: 1,
     responsive: [
       {
         breakpoint: 1024,
         settings: {
           dots: false,
           infinite: true,
           speed: 500,
           slidesToShow: 4,
           slidesToScroll: 1,
         },
       },
       {
         breakpoint: 767,
         settings: {
           dots: false,
           infinite: true,
           speed: 500,
           slidesToShow: 2,
           slidesToScroll: 1,
         },
       },
       {
         breakpoint: 600,
         settings: {
           dots: false,
           infinite: true,
           speed: 500,
           slidesToShow: 1,
           slidesToScroll: 1,
         },
       },
       {
         breakpoint: 480,
         settings: {
           dots: false,
           infinite: true,
           speed: 500,
           slidesToShow: 1,
           slidesToScroll: 1,
         },
       },
     ],
  };
  return (
    <div className="main-slider">
      <div className="container">
        <Slider {...settings}>
          {slide.map((get, keys) => {
            return (
              <div className="slider-content d-flex justify-content-center">
                <img src={get.image} alt="logo" />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default BannerSlider;
